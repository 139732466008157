import {
  Box,
  Card,
  Divider,
  Grid,
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Small, Tiny } from 'app/components/Typography';
import ManagerForm from '../../../forms/ManagerForm';
import ProfileAvatar from '../Avatar';
import { getStockRecordByCenterIdAndManagerId } from 'api-services/record-ws';
import useAuth from 'app/hooks/useAuth';

// styled components
const ImageWrapper = styled(Box)(({ theme }) => ({
  height: 100,
  position: 'relative',
  '&::before': {
    content: '""',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    opacity: 0.6,
    backgroundColor: theme.palette.primary[100],
  },
}));

const StyledAvatar = styled(ProfileAvatar)(({ theme }) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  bottom: -25,
  position: 'absolute',
  left: '50%',
  right: '50%',
  transform: 'translateX(-50%)',
  border: '2px solid',
  borderColor: theme.palette.background.paper,
}));

const ManagerUserCard = ({ manager, fetchManagerByCenter }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [setOpen] = useState(false);
  const [stockrecord, setStockRecord] = useState([]);
  const { user } = useAuth();
  useEffect(() => {
    fetchStockRecordByCenterIdAndManagerId(manager?.refCenterId, manager?._id);
  }, []);
  const fetchStockRecordByCenterIdAndManagerId = (centerId, managerId) => {
    getStockRecordByCenterIdAndManagerId(centerId, managerId, user?.authToken)
      .then((res) => {
        console.log('API Response:', res.data);
        setStockRecord(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function showSnackBar() {
    setOpen(true);
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    fetchManagerByCenter();
    setOpenDialog(false);
  };

  const uniqueIds = new Set();
  stockrecord.forEach((stock) => uniqueIds.add(stock.refStockId));
  const managingStocks = uniqueIds.size;

  return (
    <Card>
      <ImageWrapper>
        <img alt="Manager" src={manager.cover} width="100%" height="100%" />
        <StyledAvatar
          src={
            manager?.profileImage
              ? `data:image/jpeg;base64,${manager?.profileImage}`
              : manager.avatar
          }
        />
      </ImageWrapper>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: 1,
        }}
      >
        <h2 style={{ textShadow: '1px 1px 2px #888', fontFamily: ' fangsong' }}>{manager.name}</h2>
        <Tiny color="text.disabled" fontWeight={500}>
          {manager.email}
        </Tiny>
        <Button variant="outlined" onClick={handleOpenDialog} sx={{ marginTop: 2 }}>
          View Details
        </Button>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>{manager.name}'s Details</DialogTitle>
        <DialogContent>
          <ManagerForm
            manager={manager}
            centerId={manager?._id}
            handleCloseDialog={handleCloseDialog}
          />
        </DialogContent>
      </Dialog>
      <Divider sx={{ my: 2 }} />

      <Grid container style={{ marginBottom: '0px' }} spacing={1}>
        <Grid item xs={4} textAlign="center">
          <Small color="text.disabled">Monitoring Stocks</Small>
          <h3 style={{ marginTop: '0px' }}>{managingStocks}</h3>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ManagerUserCard;
