import { SimpleCard } from 'app/components';
import {
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import RecordsTable from './RecordsTable';
import { deleteRecordsByDate, getStockRecordByDate } from 'api-services/record-ws';
import PDFExporter from './PDFExporter';
import DateFilter from './DateFilter';
import moment from 'moment';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const RecordList = () => {
  const today = new Date().toISOString().split('T')[0];
  const [recordsList, setRecordsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [fromDate, setFromDate] = useState(moment(today).subtract(30, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).add(1, 'days').format('YYYY-MM-DD'));
  const [dialog, setDialog] = React.useState(false);

  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
  };
  const { user } = useAuth();
  console.log('suer', user);
  useEffect(() => {
    fetchRecordsByDate();
  }, []);
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const deleteRecordByDate = () => {
    deleteRecordsByDate(fromDate, toDate, user?.authToken)
      .then((res) => {
        console.log(res);
        setDialog(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRecordsByDate = () => {
    getStockRecordByDate(fromDate, toDate, user?._id, user?.authToken)
      .then((res) => {
        console.log(res);
        setRecordsList(res.data.data.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
  }));
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Container>
        <SimpleCard>
          <Grid>
            <DateFilter
              fetchRecordsByDate={fetchRecordsByDate}
              fromDate={fromDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              toDate={toDate}
            />
          </Grid>
          <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <PDFExporter recordsList={recordsList} />
            <Button
              style={{ marginLeft: '10px', border: '1px solid red', color: 'red' }}
              variant="outlined"
              onClick={handleDialogOpen}
            >
              Delete
            </Button>
            <Dialog
              open={dialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleDialogClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{'Delete Stock Records'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {`Are you sure you want to delete the stock records from ${fromDate} to ${toDate}
                  range?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={deleteRecordByDate}>Delete</Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </SimpleCard>

        {recordsList.length === 0 ? (
          <SimpleCard>
            <Typography>No Records yet</Typography>
          </SimpleCard>
        ) : (
          <SimpleCard title="Patient Details">
            <RecordsTable recordsList={recordsList} />
          </SimpleCard>
        )}
      </Container>
    </div>
  );
};

export default RecordList;
