import React, { useEffect, useState } from 'react';
import {
  Card,
  Box,
  Grid,
  styled,
  useTheme,
  Container,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import ManagerUserCard from './shared/userCard/ManagerUserCard';
import { getManagerDetailsByCenterId } from 'api-services/manager-ws';
import ManagerForm from '../forms/ManagerForm';
import useAuth from 'app/hooks/useAuth';
const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Managerlist = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const { user } = useAuth();
  const cover = ['/assets/images/abstract-bg-1.png'];
  const avatar = [
    '/assets/images/avatars/001-man.svg',
    '/assets/images/avatars/002-woman.svg',
    '/assets/images/avatars/003-man-1.svg',
    '/assets/images/avatars/004-bald.svg',
    '/assets/images/avatars/005-man-2.svg',
    '/assets/images/avatars/006-woman-1.svg',
    '/assets/images/avatars/007-woman-2.svg',
  ];
  const [manager, setManager] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const managerListWithAvatars = manager.map((user, index) => ({
    ...user,
    avatar: avatar[index % avatar.length],
    cover: cover[0],
  }));
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleCloseDialog = () => {
    fetchManagerByCenter();
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchManagerByCenter();
  }, []);
  const fetchManagerByCenter = () => {
    getManagerDetailsByCenterId(user?._id, user?.authToken)
      .then((res) => {
        console.log('API Response:', res.data);
        setManager(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage(err.response.data.message);
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
  console.log(user);
  return (
    <Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <StyledButton
        variant="contained"
        color="primary"
        cursor="pointer"
        backgroundColor="#ff8787"
        onClick={() => setOpenDialog(true)}
      >
        <Icon className="icon">group_add</Icon> Add Manager
      </StyledButton>
      <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
        <DialogTitle>Manager Form</DialogTitle>
        <DialogContent>
          <ManagerForm centerId={user?._id} handleCloseDialog={handleCloseDialog} />
        </DialogContent>
      </Dialog>
      <Box c pt={2} pb={4}>
        <Grid container spacing={3}>
          {managerListWithAvatars.map((user, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <ManagerUserCard fetchManagerByCenter={fetchManagerByCenter} manager={user} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Managerlist;
