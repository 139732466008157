import React, { useState } from 'react';
import useAuth from 'app/hooks/useAuth';
import CenterProfile from './CenterProfile';
import ManagerProfile from './ManagerProfile';
import NotFound from '../sessions/NotFound';
import { getCenterDetails } from 'api-services/center-ws';
import { getSelectedManagerDetails } from 'api-services/manager-ws';

const ProfilePage = () => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState(user);
  const [openDialog, setOpenDialog] = useState(false);
  const [setOpen] = useState(false);
  const [setSnackBarMessage] = useState('');
  const [setSnackBarType] = useState('success');
  const handleCloseDialog = () => {
    getUserDetails();

    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  function showSnackBar() {
    setOpen(true);
  }

  const getUserDetails = () => {
    if (user?.role === 'MANAGER') {
      console.log('here', userDetails);
      getSelectedManagerDetails(user?._id, user?.authToken)
        .then((res) => {
          setUserDetails(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
          setSnackBarMessage('Error in retrieving Profile Details');
          setSnackBarType('error');
          showSnackBar();
        });
    } else if (user?.role === 'CENTER') {
      getCenterDetails(user?._id, user?.authToken)
        .then((res) => {
          console.log(res);
          setUserDetails(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
          setSnackBarMessage('Error in retrieving Profile Details');
          setSnackBarType('error');
          showSnackBar();
        });
    }
  };

  if (user?.role === 'CENTER') {
    return (
      <CenterProfile
        userDetails={userDetails}
        openDialog={openDialog}
        handleDialogClose={handleCloseDialog}
        handleDialogOpen={handleOpenDialog}
      />
    );
  } else if (user?.role === 'MANAGER') {
    return (
      <ManagerProfile
        userDetails={userDetails}
        managerId={user?._id}
        openDialog={openDialog}
        handleDialogClose={handleCloseDialog}
        handleDialogOpen={handleOpenDialog}
      />
    );
  } else {
    return <NotFound />;
  }
};

export default ProfilePage;
