import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addStock = (stockRequest, authToken) => {
  const urlPath = '/stock/addStock';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: stockRequest,
    additionalHeader: additionalHeader,
  });
};

export const updateStockDetails = (stockRequest, authToken) => {
  const urlPath = '/stock/updateStock';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: stockRequest,
    additionalHeader: additionalHeader,
  });
};

export const getStocksByRefManagerId = (managerId, authToken) => {
  const urlPath = `/stock/getStocksByRefManagerId/${managerId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    // Authorization: `Bearer ${authToken}`,
    additionalHeader: additionalHeader,
  });
};

export const getStocksByRefCenterId = (centerId, authToken) => {
  const urlPath = `/stock/getStocksByRefCenterId/${centerId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    // Authorization: `Bearer ${authToken}`,
    additionalHeader: additionalHeader,
  });
};
