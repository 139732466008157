import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordField = ({ values, handleChange, handleBlur, touched, errors, label, name }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <TextField
      fullWidth
      size="small"
      name={name ? name : 'password'}
      type={showPassword ? 'text' : 'password'}
      label={label ? label : 'Password'}
      variant="outlined"
      onBlur={handleBlur}
      value={values}
      onChange={handleChange}
      helperText={touched.password && errors.password}
      error={Boolean(errors.password && touched.password)}
      sx={{ mb: 1.5 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
