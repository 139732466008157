import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import AboutCenter from './AboutCenter';
import CenterDashboard from './CenterDashboard';
import CstockList from './CstockList';
import ManagerDashboard from './ManagerDashboard';
import Managerlist from './Managerlist';
import Mstocklist from './Mstocklist';
import RecordList from './RecordList';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const dashboardRoutes = [
  { path: '/dashboard/center', element: <CenterDashboard />, auth: authRoles.center },
  { path: '/dashboard/manager', element: <ManagerDashboard />, auth: authRoles.manager },
  { path: '/center/stocksList', element: <CstockList />, auth: authRoles.center },
  { path: '/center/managerList', element: <Managerlist />, auth: authRoles.center },
  { path: '/manager/stocksList', element: <Mstocklist />, auth: authRoles.manager },
  { path: '/manager/aboutCenter', element: <AboutCenter />, auth: authRoles.manager },
  { path: '/center/recordsList', element: <RecordList />, auth: authRoles.center },
];

export default dashboardRoutes;
