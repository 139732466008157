import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, Button, Select, MenuItem } from '@mui/material';
// import SearchIcon from '@material-ui/icons/Search';

const StockSearchBar = ({ filterStockList, stockList }) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const inputRef = useRef(null);
  const [stockType, setStockType] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const filtered = stockList.filter((stock) =>
      stock.stockName.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStocks(filtered);
  };

  const handleFilterSubmit = () => {
    filterStockList(inputValue, stockType);
  };

  const handleItemClick = (selectedStock) => {
    setInputValue(selectedStock.stockName);
    closeDropdown();
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      closeDropdown();
    }
  };

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  const handleStockTypeChange = (event) => {
    setStockType(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="start">
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            type="text"
            value={inputValue}
            placeholder="Stock Name"
            onFocus={openDropdown}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
          />
          {isOpen && (
            <div className="dropdown-menu " style={{ border: '1px solid', borderRadius: '3px' }}>
              {filteredStocks.map((stock) => (
                <div
                  style={{
                    backgroundColor: stock.stockName === inputValue ? '#3498db' : 'transparent',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                    borderTop: '1px solid',
                    padding: '2px',
                    color: stock.stockName === inputValue ? '#ffffff' : 'inherit',
                  }}
                  key={stock._id}
                  onClick={() => handleItemClick(stock)}
                  className={stock.stockName === inputValue ? 'active' : ''}
                >
                  {stock.stockName}
                </div>
              ))}
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Select
            type="text"
            value={stockType}
            onChange={handleStockTypeChange}
            displayEmpty
            fullWidth
            style={{ height: '38px' }}
            variant="outlined"
            placeholder="Select Stock Type"
          >
            <MenuItem value="">All Stocks</MenuItem>
            <MenuItem value="GENERAL">General</MenuItem>
            <MenuItem value="ORTHO">Ortho</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Button variant="contained" color="primary" onClick={handleFilterSubmit} fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default StockSearchBar;
