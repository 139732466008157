import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const Login = (loginRequest) => {
  const urlPath = '/auth/login';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: loginRequest,
  });
};

export const Profile = (profileRequest) => {
  const urlPath = '/auth/profile';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: profileRequest,
  });
};

export const sendPasswordResetMail = (passwordResetRequest) => {
  const urlPath = '/auth/forgotPassword';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: passwordResetRequest,
  });
};

export const resetPassword = (passwordResetRequest) => {
  const urlPath = '/auth/resetPassword';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: passwordResetRequest,
  });
};
