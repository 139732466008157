import React, { useEffect, useState } from 'react';
import {
  Card,
  Box,
  Grid,
  styled,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import StockForm from '../forms/StockForm';
import { getStocksByRefCenterId } from 'api-services/stock-ws';
import CStockUserCard from './shared/userCard/CStockUserCard';
import { getStockRecordByCenterIdAndStockId } from 'api-services/record-ws';
import StockSearchBar from './StockSearchBar';
import StockPDFExporter from './stockPDFExporter';
const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const Cstocklist = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const { user } = useAuth();
  const cover = ['/assets/images/abstract-bg-1.png'];
  const avatar = [
    '/assets/images/avatars/s1.svg',
    '/assets/images/avatars/s2.svg',
    '/assets/images/avatars/s3.svg',
    '/assets/images/avatars/s4.svg',
    '/assets/images/avatars/s2.svg',
    '/assets/images/avatars/s3.svg',
    '/assets/images/avatars/s4.svg',
  ];

  const [stock, setStock] = useState([]);
  const [stockrecord, setStockRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState(null);
  const managerListWithAvatars = filteredData
    ? filteredData.map((user, index) => ({
        ...user,
        avatar: avatar[index % avatar.length],
        cover: cover[0],
      }))
    : stock.map((user, index) => ({
        ...user,
        avatar: avatar[index % avatar.length],
        cover: cover[0],
      }));
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleCloseDialog = () => {
    fetchStockByCenter(user?._id);
    setOpenDialog(false);
  };

  const filterStockList = (nameValue, stockType) => {
    const filtered = stock.filter(
      (item) =>
        (!nameValue || item.stockName.toString().includes(nameValue)) &&
        (!stockType || item.stockType?.toString()?.includes(stockType))
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchStockByCenter(user?._id);
  }, []);
  const fetchStockByCenter = (centerId) => {
    getStocksByRefCenterId(centerId, user?.authToken)
      .then((res) => {
        setStock(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(true);
        setSnackBarMessage('Error in listing Stock');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  const fetchStockRecord = (centerId, stockId) => {
    getStockRecordByCenterIdAndStockId(centerId, stockId, user?.authToken)
      .then((res) => {
        console.log(res);
        setStockRecord(res.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in listing Stock');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
  return (
    <Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
        <DialogTitle>Stock Form</DialogTitle>
        <DialogContent>
          <StockForm
            managerId={user?._id}
            centerId={user?.refCenterId}
            managerName={user?.name}
            handleCloseDialog={handleCloseDialog}
          />
        </DialogContent>
      </Dialog>
      <Grid
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
        // spacing={2}
        marginTop={2}
        alignItems="center"
      >
        <StyledCard elevation={6} sx={{ marginBottom: '20px' }}>
          <ContentBox>
            <Box ml="12px">
              {/* Use the DoctorListDropdown component */}
              <StockSearchBar filterStockList={filterStockList} stockList={stock} />
            </Box>
          </ContentBox>
          <Box>
            <StockPDFExporter stockList={managerListWithAvatars} />
          </Box>
        </StyledCard>
      </Grid>
      <Box c pt={2} pb={4}>
        <Grid container spacing={3}>
          {managerListWithAvatars.map((user, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <CStockUserCard
                fetchStockByCenter={fetchStockByCenter}
                centerId={user?.refCenterId}
                stockId={user?._id}
                stock={stock}
                user={user}
                managerId={user?.refManagerId}
                fetchStockRecord={fetchStockRecord}
                stockrecord={stockrecord}
                managerName={user?.managerName}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Cstocklist;
