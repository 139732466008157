import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addCenter = (centerRequest, authToken) => {
  const urlPath = '/center/addCenter';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: centerRequest,
    additionalHeader: additionalHeader,
  });
};

export const updateCenterDetails = (centerRequest, authToken) => {
  const urlPath = '/center/updateCenter';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: centerRequest,
    additionalHeader: additionalHeader,
  });
};

export const getAllCenterDetails = (authToken) => {
  const urlPath = '/center/getAllCenterDetails';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getCenterDetails = (centerId, authToken) => {
  const urlPath = `/center/getCenterDetails/${centerId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
