import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Grid, Snackbar, styled } from '@mui/material';
import { Avatar } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addManager, updateManagerDetails } from 'api-services/manager-ws';
import useAuth from 'app/hooks/useAuth';

// ValidatorForm.addValidationRule('isPhoneNumber', (value) => {
//   const phoneRegex = /^[0-9]{10}$/;
//   if (!phoneRegex.test(value)) {
//     return false; // Validation fails
//   }
//   return true; // Validation passes
// });

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const ManagerForm = ({
  disabled,
  manager,
  centerId,
  profileImage,
  handleCloseDialog,
  setProfileImage,
}) => {
  const [open, setOpen] = useState(false);
  const [managerName, setManagerName] = useState(manager?.name);
  const [email, setEmail] = useState(manager?.email);
  const [phoneNumber, setPhoneNumber] = useState(manager?.phoneNumber?.cell);
  const [buildingNo, setBuildingNo] = useState(manager?.address?.buildingNo);
  const [street, setStreet] = useState(manager?.address?.street);
  const [locality, setLocality] = useState(manager?.address?.locality);
  const [district, setDistrict] = useState(manager?.address?.district);
  const [state, setState] = useState(manager?.address?.state || 'Karnataka');
  const [pincode, setPincode] = useState(manager?.address?.pincode);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const { user } = useAuth();
  var managerRequest = {};
  var updateManagerRequest = {};
  updateManagerRequest = {
    _id: manager?._id,
    managerName: managerName,
    email: email,
    phoneNumber: {
      cell: phoneNumber,
      whatsapp: phoneNumber,
    },
    profileImage: profileImage,
    address: {
      buildingNo: buildingNo,
      street: street,
      locality: locality,
      district: district,
      state: state,
      pincode: pincode,
    },
  };
  managerRequest = {
    managerName: managerName,
    email: email,
    password: 'Manager@123',
    phoneNumber: {
      cell: phoneNumber,
      whatsapp: phoneNumber,
    },
    refCenterId: centerId,
    role: 'MANAGER',
    profileImage: profileImage,
    address: {
      buildingNo: buildingNo,
      street: street,
      locality: locality,
      district: district,
      state: state,
      pincode: pincode,
    },
  };

  const addhandleSubmit = (event) => {
    console.log('managerRequest:', managerRequest);

    addManager(managerRequest, user?.authToken)
      .then((res) => {
        console.log('API response:', res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Manager details Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Manager details');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log('API error:', err);
        setSnackBarMessage('Error in adding Manager details');
        setSnackBarType('error');
        showSnackBar();
      });

    console.log('submitted');
  };

  const updatehandleSubmit = (event) => {
    updateManagerDetails(updateManagerRequest, user?.authToken)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Manager details updated  Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in updating Manager details');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in updating Manager details');
        setSnackBarType('error');
        showSnackBar();
      });
    console.log('submitted');
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        setProfileImage(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <SimpleCard>
        <Avatar
          alt="Manager"
          src={`data:image/jpeg;base64,${profileImage}`}
          style={{ width: '150px', height: '150px', margin: 'auto' }}
        />

        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
            Upload Image
          </Button>
        </label>
      </SimpleCard>
      <Box py="12px" />

      <ValidatorForm onSubmit={manager ? updatehandleSubmit : addhandleSubmit} onError={() => null}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="ManagerName"
              label="Name"
              id="standard-basic"
              value={managerName || ''}
              onChange={(val) => {
                setManagerName(val.target.value);
              }}
              errorMessages={['this field is required']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="email"
              disabled={disabled}
              label="Email"
              value={email || ''}
              onChange={(val) => {
                setEmail(val.target.value);
              }}
              validators={['required', 'isEmail']}
              errorMessages={['this field is required', 'email is not valid']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text" // Use "text" type for the phone number
              name="phoneNumber"
              disabled={disabled}
              label="Phone Number"
              value={phoneNumber || ''}
              onChange={(val) => {
                // Ensure that the entered value is numeric
                const numericValue = val.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                setPhoneNumber(numericValue);
              }}
              validators={['required', 'isNumber', 'matchRegexp:^\\d{10}$']} // Validate for 10 digits
              errorMessages={[
                'This field is required',
                'Must be a number',
                'Phone number must be 10 digits',
              ]}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          Address Details
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="buildingNo"
              onChange={(val) => {
                setBuildingNo(val.target.value);
              }}
              label="Clinic Name"
              value={buildingNo || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="street"
              onChange={(val) => {
                setStreet(val.target.value);
              }}
              label="Street"
              value={street || ''}
            />
          </Grid>
          <Grid item xs={6}>
            {' '}
            <TextField
              type="text"
              name="locality"
              onChange={(val) => {
                setLocality(val.target.value);
              }}
              label="Locality"
              value={locality || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="district"
              onChange={(val) => {
                setDistrict(val.target.value);
              }}
              label="District"
              value={district || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="state"
              onChange={(val) => {
                setState(val.target.value);
              }}
              label="State"
              value={state || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              name="pincode"
              onChange={(val) => {
                setPincode(val.target.value);
              }}
              label="Pincode"
              value={pincode || ''}
              validators={['required', 'isNumber']}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {manager ? 'Save Changes' : 'Add Manager'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default ManagerForm;
