import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';

const AddStockForm = ({
  quantity,
  description,
  setDescription,
  date,
  setQuantity,
  stock,
  handleSubmit,
}) => {
  const [createdDate] = useState(moment(stock?.createdDate).format('YYYY-MM-DD'));

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Typography variant="h6" gutterBottom>
          ADD STOCK FORM
        </Typography>

        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="StockName"
              id="standard-basic"
              disabled={true}
              // value={stockName || ''}
              value={stock?.stockName || ''}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              name="quantity"
              label="Quantity"
              value={quantity || ''}
              // value={stock?.quantity}
              onChange={(val) => setQuantity(val.target.value)}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              name="managerName"
              label="Manager Name"
              // value={managerName || ''}
              value={stock?.managerName}
              validators={['required']}
              disabled={true}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              name="description"
              label="Description"
              value={description || ''}
              onChange={(val) => setDescription(val.target.value)}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              // type="date"
              // name="date"
              // label="Date"
              // value={date || ''}
              // validators={['required']}
              // errorMessages={['this field is required']}
              type="date"
              name="createdDate"
              value={createdDate || ''}
              disabled={true}
            />
          </Grid>
        </Grid>

        <div>
          <Button color="primary" variant="contained" type="submit" style={{ marginTop: '10px' }}>
            Add Stock
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AddStockForm;
