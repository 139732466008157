import {
  Box,
  Card,
  Divider,
  Grid,
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import React, { useState } from 'react';
import { Small, Tiny } from 'app/components/Typography';
import ProfileAvatar from '../Avatar';
import StockForm from 'app/views/forms/StockForm';
import AddStockForm from 'app/views/forms/AddStockForm';
import IssueStockForm from 'app/views/forms/IssueStockForm';
import moment from 'moment';
import TableUserCard from '../TableUserCard';

// styled components
const ImageWrapper = styled(Box)(({ theme }) => ({
  height: 100,
  position: 'relative',
  '&::before': {
    content: '""',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    opacity: 0.6,
    backgroundColor: theme.palette.primary[100],
  },
}));

const StyledAvatar = styled(ProfileAvatar)(({ theme }) => ({
  zIndex: 1,
  width: 100,
  height: 100,
  bottom: -25,
  position: 'absolute',
  left: '50%',
  right: '50%',
  transform: 'translateX(-50%)',
  border: '2px solid',
  borderColor: theme.palette.background.paper,
}));

const CStockUserCard = ({
  user,
  fetchStockByCenter,
  managerId,
  centerId,
  stockId,
  fetchStockRecord,
  managerName,
  stockrecord,
  addStockRecord,
  issueStockRecord,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [stockName, setStockName] = useState(user?.stockName);
  const [stockType, setStockType] = useState(user?.stockType);
  const [issueDialogOpen, setIssueDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState(user?.quantity);
  const [description, setDescription] = useState(user?.description);
  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const getCurrentDateTime = () => {
    const now = moment();

    // Format: YYYY-MM-DD HH:mm:ss
    const formattedDateTime = now.format('YYYY-MM-DD HH:mm:ss');

    return formattedDateTime;
  };

  const currentDateTime = getCurrentDateTime();
  var recordRequest = {};
  recordRequest = {
    refCenterId: centerId,
    refStockId: stockId,
    stockName: stockName,
    date: currentDateTime,
    refManagerId: managerId,
    managerName: managerName,
    quantity: quantity,
    description: description,
    stockType: stockType,
    tag: 'ADD',
  };
  var issueRecordRequest = {};
  issueRecordRequest = {
    refCenterId: centerId,
    refStockId: stockId,
    stockName: stockName,
    date: currentDateTime,
    refManagerId: managerId,
    managerName: managerName,
    quantity: quantity,
    description: description,
    stockType: stockType,
    tag: 'ISSUE',
  };
  const addStockhandleSubmit = (event) => {
    console.log('insidde submit');
    addStockRecord(recordRequest);
    console.log(recordRequest);
    setAddDialogOpen(false);
  };
  const issueStockhandleSubmit = (event) => {
    issueStockRecord(issueRecordRequest);
    console.log(recordRequest);
    handleCloseIssueStockForm();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    fetchStockByCenter(centerId);
    setOpenDialog(false);
  };
  const handleCloseAddStockForm = () => {
    setAddDialogOpen(false);
  };
  const handleCloseIssueStockForm = () => {
    setIssueDialogOpen(false);
  };
  const handleCloseTableUser = () => {
    setTableDialogOpen(false);
  };
  const handleOpenTableUser = () => {
    // console.log(centerId, stockId);
    fetchStockRecord(centerId, stockId);
    setTableDialogOpen(true);
  };

  return (
    <Card>
      <Dialog open={tableDialogOpen} onClose={handleCloseTableUser} maxWidth="md" fullWidth>
        <DialogTitle>Stock's Record</DialogTitle>
        <DialogContent>
          <TableUserCard data={stockrecord} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTableUser}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={issueDialogOpen} onClose={handleCloseIssueStockForm} maxWidth="md" fullWidth>
        <DialogTitle>Issue Stock</DialogTitle>
        <DialogContent>
          <IssueStockForm />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseIssueStockForm}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addDialogOpen} onClose={handleCloseAddStockForm} maxWidth="md" fullWidth>
        <DialogTitle>Add Stock</DialogTitle>
        <DialogContent>
          <AddStockForm
            date={currentDateTime}
            description={description}
            quantity={quantity}
            setDescription={setDescription}
            setQuantity={setQuantity}
            handleSubmit={addStockhandleSubmit}
            stock={user}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseAddStockForm}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={issueDialogOpen} onClose={handleCloseIssueStockForm} maxWidth="md" fullWidth>
        <DialogTitle>Issue Stock</DialogTitle>
        <DialogContent>
          <IssueStockForm
            date={currentDateTime}
            description={description}
            quantity={quantity}
            setDescription={setDescription}
            setQuantity={setQuantity}
            handleSubmit={issueStockhandleSubmit}
            stock={user}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseIssueStockForm}>Close</Button>
        </DialogActions>
      </Dialog>

      <ImageWrapper>
        <img src={user.cover} width="100%" height="100%" />
        <StyledAvatar
          src={user?.stockImage ? `data:image/jpeg;base64,${user?.stockImage}` : user.avatar}
          alt={user.stockName}
        />
      </ImageWrapper>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: 5,
        }}
      >
        <Tiny
          color="black"
          fontWeight={5000}
          style={{
            fontSize: '1.5em',
            color: '#brighter-color-hex', // replace with your actual color
            whiteSpace: 'normal',
            textAlign: 'center',
          }}
        >
          <span style={{ textShadow: '1px 1px 2px #888', fontFamily: 'fangsong' }}>
            {user.stockName && user.stockName.charAt(0).toUpperCase() + user.stockName.slice(1)}
          </span>
        </Tiny>

        {user.description ? <span>{user.description}</span> : <br />}
        {user.stockType ? (
          <span
            style={{
              border: '1px solid',
              backgroundColor: stockType === 'GENERAL' ? 'grey' : '#97905e',
              borderColor: stockType === 'GENERAL' ? 'grey' : '#97905e',
              borderRadius: '50px',
              padding: '5px',
              paddingLeft: '10px',
              paddingRight: '10px',
              color: 'white',
              fontWeight: 'bolder',
            }}
          >
            {user.stockType}
          </span>
        ) : (
          <br />
        )}

        <Grid>
          <Button
            variant="outlined"
            onClick={handleOpenTableUser}
            sx={{ marginTop: 2, marginRight: 4 }}
          >
            VIEW RECORD
          </Button>
          <Button variant="outlined" onClick={handleOpenDialog} sx={{ marginTop: 2 }}>
            EDIT
          </Button>{' '}
        </Grid>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={3} mb={2}>
        <Grid item xs={4} textAlign="center">
          {/* <h3>{user.post}</h3> */}
          <Small color="text.disabled" style={{ marginBottom: '0px' }}>
            Available item.
          </Small>
          <h3 style={{ marginTop: '0px' }}>{user?.quantity}</h3>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <h3>{user.follower}</h3>
          {/* <a onClick={handleOpenIssueStockForm}>Issue</a> */}
        </Grid>
        <Grid item xs={4} textAlign="center">
          <h3>{user.following}</h3>
          {/* <a onClick={handleOpenAddStockForm}>Add</a> */}
          <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
            <DialogTitle>{user.stockName}'s Details</DialogTitle>
            <DialogContent>
              <StockForm
                centerId={user?._id}
                stock={user}
                managerName={user?.managerName}
                handleCloseDialog={handleCloseDialog}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CStockUserCard;
