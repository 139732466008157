import React from 'react';
import jsPDF from 'jspdf';
import { Button } from '@mui/material';

const exportToPDF = (jsonDataArray, itemsPerPage = 25) => {
  const pdf = new jsPDF();

  const headers = ['Sl. No.', 'Date', 'Stock Name', 'Qty', 'Manager', 'Stock Type'];
  const columnWidths = [15, 25, 100, 10, 20, 20, 10];
  const rowHeight = 10;
  let currentY = 10;
  let currentPage = 1;
  let currentRow = 0;

  pdf.setFontSize(16);
  pdf.setFont('helvetica', 'bold');
  pdf.setTextColor(0, 0, 0);

  pdf.text('The Classical Dental Clinic - Stock List', 60, currentY);

  currentY += 10;

  pdf.setFontSize(9);
  pdf.setTextColor(0, 0, 0);

  // Draw column headers
  pdf.rect(10, currentY, 195, rowHeight, 'S');
  headers.forEach((header, index) => {
    pdf.text(
      header,
      15 + columnWidths.slice(0, index).reduce((acc, val) => acc + val, 0),
      currentY + rowHeight / 2,
      {
        align: 'left',
        baseline: 'middle',
      }
    );
  });

  currentY += rowHeight;

  jsonDataArray.forEach((data, index) => {
    if (currentRow === itemsPerPage) {
      pdf.addPage();
      currentPage++;
      currentY = 10;
      currentRow = 0;

      pdf.setFontSize(16);
      pdf.setFont('helvetica', 'bold');
      pdf.setTextColor(0, 0, 0);
      pdf.text('The Classical Dental Clinic - Stock List', 60, currentY);
      currentY += 10;

      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);

      pdf.rect(10, currentY, 195, rowHeight, 'S');
      headers.forEach((header, index) => {
        pdf.text(
          header,
          15 + columnWidths.slice(0, index).reduce((acc, val) => acc + val, 0),
          currentY + rowHeight / 2,
          {
            align: 'left',
            baseline: 'middle',
          }
        );
      });

      // Move to the next row
      currentY += rowHeight;
    }

    const rowData = [
      index + 1,
      new Date(data.createdDate).toLocaleDateString(),
      data.stockName,
      data.quantity,
      data.managerName,
      data.stockType,
    ];

    pdf.rect(10, currentY, 195, rowHeight, 'S');
    rowData.forEach((cellData, cellIndex) => {
      pdf.text(
        cellData.toString(),
        15 + columnWidths.slice(0, cellIndex).reduce((acc, val) => acc + val, 0),
        currentY + rowHeight / 2,
        {
          align: 'left',
          baseline: 'middle',
        }
      );
    });

    currentY += rowHeight;
    currentRow++;
  });

  pdf.save('records.pdf');
};

const StockPDFExporter = ({ stockList }) => {
  console.log(stockList);
  return (
    <div>
      <Button style={{ border: '1px solid' }} onClick={() => exportToPDF(stockList)}>
        Export to PDF
      </Button>
    </div>
  );
};

export default StockPDFExporter;
