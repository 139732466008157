import { Alert, Box, Button, Grid, Snackbar, styled } from '@mui/material';
import { Avatar, MenuItem, Select, InputLabel } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addStock, updateStockDetails } from 'api-services/stock-ws';
import moment from 'moment';
import useAuth from 'app/hooks/useAuth';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const StockForm = ({ managerId, centerId, stock, managerName, handleCloseDialog }) => {
  const [open, setOpen] = useState(false);
  const [stockName, setStockName] = useState(stock?.stockName);
  const [stockImage, setStockImage] = useState(stock?.stockImage);
  const [createdDate] = useState(moment(stock?.createdDate).format('YYYY-MM-DD'));
  const [quantity] = useState(stock?.quantity);
  const [description, setDescription] = useState(stock?.description);
  const [stockType, setStockType] = useState(stock?.stockType ? stock?.stockType : 'GENERAL');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const { user } = useAuth();
  var stockRequest = {};
  stockRequest = {
    _id: stock?._id,
    stockName: stockName,
    stockImage: stockImage,
    refCenterId: centerId,
    refManagerId: managerId,
    createdDate: createdDate,
    managerName: managerName,
    quantity: quantity || 0,
    description: description,
    stockType: stockType,
  };
  var updateStockRequest = {};
  updateStockRequest = {
    stockName: stockName,
    _id: stock?._id,
    createdDate: createdDate,
    managerName: managerName,
    quantity: quantity,
    description: description,
    stockImage: stockImage,
    stockType: stockType,
  };
  const addhandleSubmit = (event) => {
    addStock(stockRequest, user?.authToken)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Stock Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Stock');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Stock');
        setSnackBarType('error');
        showSnackBar();
      });

    console.log('submitted');
  };

  const updatehandleSubmit = (event) => {
    updateStockDetails(updateStockRequest, user?.authToken)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Stock Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in Adding Stock');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in Updating Stock');
        setSnackBarType('error');
        showSnackBar();
      });
    console.log('submitted');
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleStockTypeChange = (event) => {
    setStockType(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        setStockImage(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Box py="12px" />
      <SimpleCard>
        <Avatar
          alt="Manager"
          src={`data:image/jpeg;base64,${stockImage}`}
          style={{
            width: '150px',
            height: '150px',
            margin: 'auto',
          }}
        />

        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
            Upload Image
          </Button>
        </label>
      </SimpleCard>

      <ValidatorForm onSubmit={stock ? updatehandleSubmit : addhandleSubmit} onError={() => null}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={6}>
            <TextField
              type="text"
              name="stockName"
              id="standard-basic"
              value={stockName || ''}
              onChange={(val) => {
                setStockName(val.target.value);
              }}
              errorMessages={['this field is required']}
              label="StockName"
              validators={['required']}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField type="date" name="createdDate" value={createdDate || ''} disabled={true} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="managerName"
              label="Manager Name"
              value={managerName || ''}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="desciption"
              label="Description"
              value={description || ''}
              onChange={(val) => {
                setDescription(val.target.value);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel id="stock-type-label">Stock Type</InputLabel>

            <Select
              type="text"
              value={stockType}
              onChange={handleStockTypeChange}
              displayEmpty
              fullWidth
              variant="outlined"
              placeholder="Select Stock Type"
            >
              <MenuItem value="GENERAL">General</MenuItem>
              <MenuItem value="ORTHO">Ortho</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {stock ? 'Save Changes' : 'Add Stock'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default StockForm;
