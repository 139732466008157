import Typography from '@mui/material/Typography';
import { Avatar, Alert, Button, Grid, Snackbar, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addCenter, updateCenterDetails } from 'api-services/center-ws';
import useAuth from 'app/hooks/useAuth';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const CenterForm = ({ disabled, center, handleCloseDialog, setCenterLogo, centerLogo }) => {
  const [open, setOpen] = useState(false);
  const [centerName, setCenterName] = useState(center?.centerName);
  const [centerPhone, setCenterPhone] = useState(center?.centerPhone?.cell);
  const [email, setEmail] = useState(center?.email);
  const [firstName, setFirstName] = useState(center?.ownerName?.firstName);
  const [lastName, setLastName] = useState(center?.ownerName?.lastName);
  const [buildingNo, setBuildingNo] = useState(center?.address.buildingNo);
  const [street, setStreet] = useState(center?.address.street);
  const [locality, setLocality] = useState(center?.address.locality);
  const [district, setDistrict] = useState(center?.address.district);
  const [state, setState] = useState(center?.address.state || 'Karnataka');
  const [pincode, setPincode] = useState(center?.address.pincode);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const { user } = useAuth();
  var centerRequest = {};
  var updateCenterRequest = {};
  console.log(center);
  updateCenterRequest = {
    _id: center?._id,
    centerLogo: centerLogo,
    centerName: centerName,
    centerPhone: {
      cell: centerPhone,
      whatsapp: centerPhone,
    },
    email: email,
    ownerName: {
      firstName: firstName,
      lastName: lastName,
    },
    role: 'CENTER',
    address: {
      buildingNo: buildingNo,
      street: street,
      locality: locality,
      district: district,
      state: state,
      pincode: pincode,
    },
  };
  centerRequest = {
    centerLogo: centerLogo,
    centerName: centerName,
    centerPhone: {
      cell: centerPhone,
      whatsapp: centerPhone,
    },
    email: email,
    password: 'Center@123',
    ownerName: {
      firstName: firstName,
      lastName: lastName,
    },
    role: 'CENTER',
    address: {
      buildingNo: buildingNo,
      street: street,
      locality: locality,
      district: district,
      state: state,
      pincode: pincode,
    },
  };
  const updateCenterSubmit = (event) => {
    updateCenterDetails(updateCenterRequest, user?.authToken)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Center Updated Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in updating Center');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in updating Center');
        setSnackBarType('error');
        showSnackBar();
      });
    console.log('submitted');
  };
  const handleSubmit = (event) => {
    addCenter(centerRequest, user?.addCenter)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Center Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Center');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Center');
        setSnackBarType('error');
        showSnackBar();
      });
    console.log('submitted');
  };

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        setCenterLogo(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <SimpleCard>
        <Avatar
          alt="Center"
          src={`data:image/jpeg;base64,${centerLogo}`}
          style={{ width: '150px', height: '150px', margin: 'auto' }}
        />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
            Upload Logo
          </Button>
        </label>
      </SimpleCard>

      {/* <Box py="12px" /> */}

      <ValidatorForm onSubmit={center ? updateCenterSubmit : handleSubmit} onError={() => null}>
        <Typography variant="h6" gutterBottom>
          Center Details
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="CenterName"
              id="standard-basic"
              value={centerName || ''}
              onChange={(val) => setCenterName(val.target.value)}
              errorMessages={['this field is required']}
              label="Centername (Min length 4, Max length 30)"
              validators={['required', 'minStringLength: 4', 'maxStringLength: 30']}
            />
          </Grid>

          <Grid item xs={6}>
            <TextValidator
              type="text" // Use type "text" to avoid arrows
              disabled={disabled}
              name="centerPhone"
              value={centerPhone || ''}
              label="Center Phone"
              onChange={(val) => setCenterPhone(val.target.value)}
              validators={['required', 'isNumber', 'matchRegexp:^\\d{10}$']} // Add the custom validator
              errorMessages={[
                'This field is required',
                'Please enter only numbers',
                'Please enter a 10-digit phone number',
                'Please enter a 10-digit phone number',
              ]}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="firstname"
              label="Firstname (Min length 4, Max length 20)"
              onChange={(val) => setFirstName(val.target.value)}
              value={firstName || ''}
              errorMessages={['this field is required']}
              validators={['required', 'minStringLength: 4', 'maxStringLength: 20']}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="lastname"
              label="Lastname"
              onChange={(val) => setLastName(val.target.value)}
              value={lastName || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={disabled}
              type="text"
              name="email"
              label="Email"
              value={email || ''}
              onChange={(val) => setEmail(val.target.value)}
              validators={['required', 'isEmail']}
              errorMessages={['this field is required', 'email is not valid']}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom>
          Address Details
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="buildingNo"
              onChange={(val) => setBuildingNo(val.target.value)}
              label="Building Name/Number"
              value={buildingNo || ''}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </Grid>

          <Grid item xs={6}>
            {/* <Typography variant="h6" gutterBottom></Typography> */}
            <TextField
              type="text"
              name="street"
              onChange={(val) => setStreet(val.target.value)}
              label="Street"
              value={street || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="locality"
              onChange={(val) => setLocality(val.target.value)}
              label="Locality"
              value={locality || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="district"
              onChange={(val) => setDistrict(val.target.value)}
              label="District"
              value={district || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="state"
              onChange={(val) => setState(val.target.value)}
              label="State"
              value={state || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="number"
              name="pincode"
              onChange={(val) => setPincode(val.target.value)}
              label="Pincode"
              value={pincode || ''}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {center ? 'Save Changes' : 'Add Center'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default CenterForm;
