import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addStockRecords = (recordRequest, authToken) => {
  const urlPath = '/record/addStockRecord';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: recordRequest,
    additionalHeader: additionalHeader,
  });
};

export const issueStock = (recordRequest, authToken) => {
  const urlPath = '/record/issueStockRecord';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: recordRequest,
    additionalHeader: additionalHeader,
  });
};

export const getStockRecordByCenterIdAndStockId = (refCenterId, refStockId, authToken) => {
  const urlPath = `/record/getStockRecordByCenterIdAndStockId/${refCenterId}/${refStockId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
export const getStockRecordByDate = (fromDate, toDate, refCenterId, authToken) => {
  const urlPath = `/record/getRecordsByDate/${refCenterId}/${fromDate}/${toDate}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getStockRecordByCenterIdAndManagerId = (refCenterId, refManagerId, authToken) => {
  const urlPath = `/record/getStockRecordByCenterIdAndManagerId/${refCenterId}/${refManagerId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getStockRecordByCenterId = (refCenterId, authToken) => {
  const urlPath = `/record/getStockRecordByCenterId/${refCenterId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const deleteRecordsByDate = (fromDate, toDate, authToken) => {
  const urlPath = `/record/DeleteRecordsByDate/${fromDate}/${toDate}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
