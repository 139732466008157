import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Avatar,
  Grid,
  Box,
  Paper,
  Divider,
  useMediaQuery,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ManagerForm from '../forms/ManagerForm';
import profilePic from './../../views/patient_referral.jpg';

const ManagerProfile = ({ userDetails, openDialog, handleDialogClose, handleDialogOpen }) => {
  const { email, name, phoneNumber, address } = userDetails;
  const [profileImage, setProfileImage] = useState(userDetails?.profileImage);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  console.log('next', userDetails);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
        background: 'linear-gradient(90deg, #1e3c72 34%, #2a5298 60%)',
      }}
    >
      <Grid item xs={8} sm={4} lg={8}>
        <Card variant="outlined">
          <Typography
            variant="h3"
            sx={{ marginTop: '20px', textAlign: 'center', fontFamily: 'Geogria' }}
          >
            PROFILE
          </Typography>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  flex: 1,
                  borderRadius: '16px',
                  marginRight: isSmallScreen ? '80px' : '1rem',
                  marginBottom: isSmallScreen ? '1rem' : '80px',
                  justifyContent: 'Center',
                  alignContent: 'center',
                  maxHeight: '330px',
                  maxWidth: '300px',
                }}
              >
                <CardContent style={{}}>
                  <Avatar
                    src={profileImage ? `data:image/jpeg;base64,${profileImage}` : profilePic}
                    sx={{
                      width: isSmallScreen ? '100%' : '250px',
                      height: isSmallScreen ? 'auto' : '100%',
                      borderRadius: '16px',
                      justifyContent: 'center',
                      display: 'flex',
                      objectFit: 'contain',
                    }}
                  />
                </CardContent>
              </Card>
              <Paper
                sx={{
                  color: 'black',
                  padding: '2rem',
                  flex: 1,
                  borderRadius: '16px',
                  elevation: '0',
                  boxShadow: 'none',
                }}
              >
                <Typography
                  variant="h4"
                  style={{ marginTop: isSmallScreen ? '0' : '-20px', marginBottom: '40px' }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    margin: '4px',
                    alignItems: 'center',
                    marginTop: '1rem',
                    fontSize: '1.2rem',
                  }}
                >
                  <EmailIcon style={{ marginRight: '8px', fontSize: '1.2rem' }} /> {email}
                </Typography>
                <Divider style={{ margin: '8px', width: '80%' }} />
                <Typography
                  variant="body1"
                  sx={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem', margin: '4px' }}
                >
                  <PhoneIcon style={{ marginRight: '8px', fontSize: '1.2rem' }} />{' '}
                  {phoneNumber?.cell}
                </Typography>
                <Divider style={{ margin: '8px', width: '80%' }} />
                <div
                  style={{
                    marginTop: '1rem',
                    borderRadius: '8px',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '1.5rem',
                      fontSize: '1.2rem',
                    }}
                  >
                    <LocationOnIcon style={{ marginRight: '8px' }} />
                    {`${address?.buildingNo || ''}`}
                    <br />
                    {`${address?.locality || ''} ${address?.street || ''} `}
                    <br />
                    {`${address?.district || ''}`} <br />
                    {`${address?.state} - ${address?.pincode}`}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: '1rem',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    justifyContent="none"
                    onClick={handleDialogOpen}
                  >
                    Edit Profile
                  </Button>
                </div>
              </Paper>
              <Dialog
                onClose={() => {
                  handleDialogClose();
                  // fetchManagerByCenter();
                }}
                open={openDialog}
                // fetchManagerByCenter={fetchManagerByCenter}
              >
                <DialogTitle>Manager Form</DialogTitle>
                <DialogContent>
                  <ManagerForm
                    setProfileImage={setProfileImage}
                    profileImage={profileImage}
                    manager={userDetails}
                    handleCloseDialog={handleDialogClose}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ManagerProfile;
