// import React, { useState } from 'react';
// import {
//   Card,
//   CardContent,
//  Grid,
// } from '@mui/material';
// const AboutCenter = ({ userDetails, openDialog, handleDialogClose, handleDialogOpen }) => {
//   const [email, setEmail] = useState(userDetails?.email);
//   const [name, setName] = useState(userDetails?.name);
//   const [phoneNumber, setPhoneNumber] = useState(userDetails?.phoneNumber);
//   const [address, setAddress] = useState(userDetails?.address);
//   const [profileImage, setProfileImage] = useState(userDetails?.adminProfile);
//   //   const classes = useStyles();
//   return (
//     <Grid
//       container
//       justifyContent="center"
//       alignItems="center"
//       style={{
//         height: '100vh',
//         // background: '#1A2038',
//         // backgroundImage: url(`${backgroundImage}`),
//         backgroundSize: 'cover', // Adjust as needed
//         backgroundRepeat: 'no-repeat', // Adjust as needed
//         width: '100%',
//         backgroundImage: 'linear-gradient(90deg, #e1ddcd 10%, #b8ad92 100%)',
//       }}
//     >
//       <Grid item xs={12} sm={9} >
//         <Card variant="outlined" style={{maxHeight:'1000px'}}>
//          <CardContent>


          
//          </CardContent>
//         </Card>
//       </Grid>
//     </Grid>
//   );
// };

// export default AboutCenter;



// import React from 'react';
// import { Card, Grid } from '@mui/material';
// import centerabbout from './centrabout.jpg';

// const AboutCenter = () => {
//   const pageStyles = {
//     backgroundImage: 'linear-gradient(90deg, #1e3c72 34%, #2a5298 60%)',
//     minHeight: '100vh', 
//   };

//   return (
//     <Card style={pageStyles}>
//       <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        
//       <Grid item xs={12} sm={6} style={{ height: '200px', maxHeight: '80vh' }}>
//   <img
//     src={centerabbout}
//     width="100%"
//     alt=""
//     style={{ borderRadius: '10px', width: '90%', height: '90%', objectFit: 'cover' }}
//   />
// </Grid>

       
//         <Grid item xs={12} sm={6}>
//           <Card style={{ padding: '20px' }}>
           
//             <h2>About Us</h2>
//             <p>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
//               accumsan tincidunt justo, vel feugiat diam volutpat vel.
//             </p>
//           </Card>
//         </Grid>
//       </Grid>
//     </Card>
//   );
// };

// export default AboutCenter;


// import React from 'react';
// import { Card, Grid } from '@mui/material';
// import centerabout from './centrabout.jpg';

// const AboutCenter = () => {
//   const pageStyles = {
//     backgroundImage: 'linear-gradient(90deg, #1e3c72 34%, #2a5298 60%)',
//     minHeight: '100vh',
//     padding: '20px', // Add padding for spacing
//   };

//   const imageCardStyles = {
//     marginRight: '20px', // Adjust spacing between image and content cards
//   };

//   return (
//     <Card style={pageStyles}>
//       <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100%' }}>
//         {/* Image Card */}
//         <Card style={imageCardStyles}>
//           <Grid item xs={12} sm={6} style={{ height: '300px', maxHeight: '80vh' }}>
//             <img
//               src={centerabout}
//               width="100%"
//               alt=""
//               style={{ borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' }}
//             />
//           </Grid>
//         </Card>

//         {/* Content Card */}
//         <Card style={{ padding: '20px' }}>
//           <Grid item xs={12} sm={6}>
//             <h2>About Us</h2>
//             <p>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan tincidunt justo, vel feugiat diam volutpat vel.
//             </p>
//           </Grid>
//         </Card>
//       </Grid>
//     </Card>
//   );
// };

// export default AboutCenter;
import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Grid, Typography,Button } from '@mui/material';
import centerabout from './centrabout.jpg';

const AboutCenter = ({  }) => {
  
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
        width: '100%',
        backgroundImage: 'linear-gradient(90deg, #e1ddcd 10%, #b8ad92 100%)',
      }}
    >
      <Grid item xs={12} sm={9}>
        <Card variant="outlined" style={{ maxHeight: '1000px' }}>
          {/* Main Card Content */}
          <CardContent>
            <Grid container spacing={2}>
              {/* Image Card */}
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardMedia
                    component="img"
                    height="400"
                    width="300"
                    alt="Center Image"
                    image={centerabout} 
                    style={{ borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Card>
              </Grid>

              {/* Description Card */}
              <Grid item xs={12} sm={6}>
                <Card style={{height: '410px' }} >
                  <CardContent>
                  <Typography variant="h4" style={{ fontFamily: 'Arial' }}>
                     Your Smile, Our Priority
                  </Typography>
                  <Typography style={{ fontFamily: 'Arial',fontSize:"20px" }}>
                      We are dedicated to providing top-notch dental care with a focus on your oral health and a bright,
                      confident smile. Discover how our commitment to excellence and personalized care sets us apart in the world of dentistry.
                  </Typography>
                  
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AboutCenter;
