import { authRoles } from './auth/authRoles';
export const navigations = [
  { name: 'Dashboard', path: '/dashboard/center', icon: 'dashboard', auth: authRoles.center },
  { name: 'Dashboard', path: '/dashboard/manager', icon: 'dashboard', auth: authRoles.manager },
  {
    name: 'Stocks',
    path: '/center/stocksList',
    icon: 'local_grocery_store',
    auth: authRoles.center,
  },
  { name: 'Managers', path: '/center/managerList', icon: 'group', auth: authRoles.center },
  { name: 'Stocks ', path: '/manager/stocksList', icon: 'inventory', auth: authRoles.manager },
  { name: 'Records', path: '/center/recordsList', icon: 'assignment', auth: authRoles.center },
  // {
  //   name: 'About Center',
  //   path: '/manager/aboutCenter',
  //   icon: 'dashboard',
  //   auth: authRoles.manager,
  // },
];
