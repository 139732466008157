import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, Button, Typography, Divider, Container, Card } from '@mui/material';
// import SearchIcon from '@material-ui/icons/Search';

const DateFilter = ({
  fetchRecordsByDate,
  patientList,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const inputRef = useRef(null);

  const handleFilterSubmit = () => {
    fetchRecordsByDate(fromDate, toDate);
  };

  const handleItemClick = (selectedPatient) => {
    setInputValue(selectedPatient.patientPhone);
    closeDropdown();
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      closeDropdown();
    }
  };

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3} md={3}>
          <TextField
            fullWidth
            label="From"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <TextField
            fullWidth
            label="To"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
            Search
          </Button>
        </Grid>
      </Grid>
      {isOpen && (
        <Card
          className="dropdown-menu"
          style={{
            width: '130px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            border: '1px solid ',
            borderColor: 'grey',
          }}
        >
          {filteredPatients.map((patient) => (
            <Grid
              style={{ border: '2px', margin: '2px', textAlign: 'center' }}
              fullWidth
              key={patient._id}
              onClick={() => handleItemClick(patient)}
              className={patient.patientPhone === inputValue ? 'active' : ''}
            >
              <Typography>{patient.patientPhone}</Typography>
              <Divider />
            </Grid>
          ))}
        </Card>
      )}
    </div>
  );
};

export default DateFilter;
