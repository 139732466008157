import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addManager = (managerRequest, authToken) => {
  const urlPath = '/manager/addManager';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: managerRequest,
    additionalHeader: additionalHeader,
  });
};

export const updateManagerDetails = (managerRequest, authToken) => {
  const urlPath = '/manager/updateManager';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: managerRequest,
    additionalHeader: additionalHeader,
  });
};

export const getAllManagerDetails = (authToken) => {
  const urlPath = '/manager/getAllManagerDetails';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
export const getSelectedManagerDetails = (managerId, authToken) => {
  const urlPath = `/manager/getManagerDetails/${managerId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
export const getManagerDetailsByCenterId = (refCenterId, authToken) => {
  const urlPath = `/manager/getManagerByRefCenterId/${refCenterId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const managerOnboardingMail = (mailRequest, authToken) => {
  const urlPath = '/manager/managerOnboardingMail';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: mailRequest,
    additionalHeader: additionalHeader,
  });
};
