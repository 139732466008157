// import React from 'react';
// import { Card, Grid, styled, useTheme } from '@mui/material';

// const ContentBox = styled('div')(({ theme }) => ({
//   margin: '30px',
//   [theme.breakpoints.down('sm')]: { margin: '16px' },
// }));

// const ManagerDashboard = () => {
//   const { palette } = useTheme();

//   // Dummy data for the number of stocks
//   const numberOfStocks = 100;

//   return (
//     <ContentBox className="managerdashboard">
//       <Grid container spacing={3}>
//         {/* Card for Number of Stocks */}
//         <Grid item xs={12}>
//           <Card>
//             <h2>Number of Stocks</h2>
//             <p>{numberOfStocks}</p>
//           </Card>
//         </Grid>

//       </Grid>
//     </ContentBox>
//   );
// };

// export default ManagerDashboard;
import React, { useState, useEffect } from 'react';
import { styled, Box, Icon, Card, Grid, useTheme } from '@mui/material';
// import { Bar } from 'react-chartjs-2';
import StockForm from '../forms/StockForm';
import { getStocksByRefManagerId } from 'api-services/stock-ws';
import useAuth from 'app/hooks/useAuth';
import { Small } from 'app/components/Typography';
import { Link } from 'react-router-dom';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const ManagerDashboard = () => {
  const { palette } = useTheme();
  const [stock, setStock] = useState([]);
  const [stocksList, setStocksList] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const { user } = useAuth();
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px !important',
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: { padding: '16px !important' },
    margin: '10px',
  }));
  const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& small': { color: theme.palette.text.secondary },
    '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
  }));

  const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    marginRight: '.5rem',
    textTransform: 'capitalize',
  }));

  const SubTitle = styled('span')(({ theme }) => ({
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  }));

  const Heading = styled('h6')(({ theme }) => ({
    margin: 0,
    marginTop: '4px',
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  }));

  useEffect(() => {
    fetchStockByManager(user?._id);
  }, [user?._id]);
  console.log(user);
  const fetchStockByManager = (managerId) => {
    getStocksByRefManagerId(managerId, user?.authToken)
      .then((res) => {
        setStocksList(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(true);
        setSnackBarMessage('Error in listing Stock');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  function showSnackBar() {
    setOpen(true);
  }
  const stockNames = stocks.map((stock) => stock.stockName);
  const stockQuantities = stocks.map((stock) => stock.quantity);
  const cardList = [
    {
      name: 'Number of Stocks',
      total: stocksList.length,
      icon: 'shopping_cart',
      link: '/manager/stocksList',
    },
  ];

  return (
    <Grid container spacing={3} sx={{ mb: '24px' }}>
      {cardList.map((item, index) => (
        <Grid item xs={12} md={6} key={index}>
          <StyledCard elevation={6} component={Link} to={item.link}>
            <ContentBox>
              <Icon className="icon">{item.icon}</Icon>
              <Box ml="12px">
                <Small>{item.name}</Small>
                <Heading>{item.total}</Heading>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default ManagerDashboard;
