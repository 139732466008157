import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';

const IssueStockForm = ({
  quantity,
  description,
  setDescription,
  date,
  setQuantity,
  stock,
  handleSubmit,
}) => {
  const [createdDate] = useState(moment(stock?.createdDate).format('YYYY-MM-DD'));
  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Typography variant="h6" gutterBottom>
          ISSUE STOCK FORM
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="StockName"
              id="standard-basic"
              disabled={true}
              // value={stockName || ''}
              value={stock?.stockName || ''}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              name="quantity"
              label="Quantity"
              value={quantity || ''}
              // value={stock?.quantity}
              onChange={(val) => setQuantity(val.target.value)}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              name="managerName"
              label="Manager Name"
              // value={managerName || ''}
              value={stock?.managerName}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              name="description"
              label="Description"
              value={description || ''}
              onChange={(val) => setDescription(val.target.value)}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField type="date" name="createdDate" value={createdDate || ''} disabled={true} />
          </Grid>
        </Grid>

        <div>
          <Button color="primary" variant="contained" type="submit" style={{ marginTop: '10px' }}>
            Issue Stock
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default IssueStockForm;
