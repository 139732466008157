import React, { useEffect, useState } from 'react';
import {
  Card,
  Box,
  Grid,
  styled,
  useTheme,
  Container,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
} from '@mui/material';

import useAuth from 'app/hooks/useAuth';
import StockForm from '../forms/StockForm';
import { getStocksByRefCenterId } from 'api-services/stock-ws';
import {
  addStockRecords,
  issueStock,
  getStockRecordByCenterIdAndStockId,
} from 'api-services/record-ws';
import MStockUserCard from './shared/userCard/MStockUserCard';
import StockSearchBar from './StockSearchBar';
const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));
const Mstocklist = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [filteredData, setFilteredData] = useState(null);
  const { user } = useAuth();
  console.log(user);
  const cover = ['/assets/images/abstract-bg-1.png'];
  const avatar = [
    '/assets/images/avatars/s1.svg',
    '/assets/images/avatars/s1.svg',
    '/assets/images/avatars/s1.svg',
    '/assets/images/avatars/s1.svg',
    '/assets/images/avatars/s1.svg',
    '/assets/images/avatars/s1.svg',
    '/assets/images/avatars/s1.svg',
  ];
  const [stock, setStock] = useState([]);
  const [stockrecord, setStockRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const managerListWithAvatars = filteredData
    ? filteredData.map((user, index) => ({
        ...user,
        avatar: avatar[index % avatar.length],
        cover: cover[0],
      }))
    : stock.map((user, index) => ({
        ...user,
        avatar: avatar[index % avatar.length],
        cover: cover[0],
      }));
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleCloseDialog = () => {
    fetchStockByCenter(user?.refCenterId);
    setOpenDialog(false);
  };
  const filterStockList = (nameValue, stockType) => {
    const filtered = stock.filter(
      (item) =>
        (!nameValue || item.stockName?.toString()?.includes(nameValue)) &&
        (!stockType || item.stockType?.toString()?.includes(stockType))
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchStockByCenter(user?.refCenterId);
  }, []);
  const fetchStockByCenter = (centerId) => {
    getStocksByRefCenterId(centerId, user?.authToken)
      .then((res) => {
        setStock(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in listing Stock');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const fetchStockRecord = (centerId, stockId) => {
    getStockRecordByCenterIdAndStockId(centerId, stockId, user?.authToken)
      .then((res) => {
        console.log(res);
        setStockRecord(res.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in listing Stock');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  const addStockRecord = (recordRequest) => {
    addStockRecords(recordRequest, user?.authToken)
      .then((res) => {
        console.log('res', res);
        if (res.status === 200) {
          setSnackBarMessage('Stock Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Stock');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Stock');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  const issueStockRecord = (issueRecordRequest) => {
    issueStock(issueRecordRequest, user?.authToken)
      .then((res) => {
        console.log('res', res);
        if (res.status === 200) {
          setSnackBarMessage('Stock issued Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in issuing Stock');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in issuing Stock');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
  return (
    <Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
        <Icon className="icon">group_add</Icon> Add Stock
      </StyledButton>
      <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
        <DialogTitle>Stock Form</DialogTitle>
        <DialogContent>
          <StockForm
            managerId={user?._id}
            centerId={user?.refCenterId}
            managerName={user?.name}
            handleCloseDialog={handleCloseDialog}
          />
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} marginTop={2} alignItems="center">
        <Grid item xs={12} md={6} sm={6}>
          <StyledCard elevation={6} sx={{ marginBottom: '20px' }}>
            <ContentBox>
              <Box ml="12px">
                {/* Use the DoctorListDropdown component */}
                <StockSearchBar filterStockList={filterStockList} stockList={stock} />
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
      </Grid>
      <Box c pt={2} pb={4}>
        <Grid container spacing={3}>
          {managerListWithAvatars.map((item, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <MStockUserCard
                stock={stock}
                user={item}
                fetchStockByCenter={fetchStockByCenter}
                managerId={user?._id}
                fetchStockRecord={fetchStockRecord}
                stockrecord={stockrecord}
                addStockRecord={addStockRecord}
                issueStockRecord={issueStockRecord}
                managerName={user?.name}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Mstocklist;
