import React, { useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { bgcolors } from 'app/utils/utils';

// styled components

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600, // Adjust the minimum width as needed
  overflowX: 'auto',
});

const TableUserCard = ({ data }) => {
  console.log(data);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // const Small = styled('small')(({ bgcolor }) => ({
  // width: 50,
  // fontWeight: '400',
  // height: 15,
  // color: 'white',
  // padding: '2px 8px',
  // borderRadius: '4px',
  // overflow: 'hidden',
  // background: bgcolor,
  // boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  const Small = styled('small')(({ outlineColor }) => ({
    width: 50,
    fontWeight: '400',
    height: 15,
    color: outlineColor,
    padding: '2px 8px',
    borderRadius: '4px',
    overflow: 'hidden',
    background: 'none', // No background color
    boxShadow: `0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24), inset 0 0 0 2px ${outlineColor}`,
  }));
  // const getColorForTag = (status) => {
  //   return bgcolors[Tag] || '#000'; // Default to black if the status is not recognized
  // };
  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Date</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Stock Name</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Manager Name</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Tag</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Quantity</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Description</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Remaining Stock</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((stock, index) => (
            <TableRow
              key={index}
              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
            >
              <TableCell align={isXsScreen ? 'left' : 'center'}>
                {new Date(stock?.date).toLocaleDateString()}
              </TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>{stock?.stockName}</TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>{stock?.managerName}</TableCell>
              <TableCell align="center">
                {/* <Small bgcolor={getColorForTag(stock?.tag)}>{stock?.tag}</Small> */}
                <Small outlineColor={bgcolors[stock?.tag]}>{stock?.tag}</Small>
              </TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>{stock?.quantity}</TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>{stock?.description}</TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>{stock?.remainingStock}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={'Records per page'}
        count={data.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default TableUserCard;
