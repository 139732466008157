import {
  Box,
  Card,
  Grid,
  Icon,
  styled,
  Button,
  useTheme,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Small } from 'app/components/Typography';
import React, { useEffect, useState } from 'react';
import useAuth from 'app/hooks/useAuth';
import { Link } from 'react-router-dom';
import ManagerForm from '../forms/ManagerForm';
import { getManagerDetailsByCenterId } from 'api-services/manager-ws';
import { getStocksByRefCenterId } from 'api-services/stock-ws';
import DoughnutChart from './shared/Doughnut';

// const ContentBox = styled('div')(({ theme }) => ({
//   margin: '30px',
//   [theme.breakpoints.down('sm')]: { margin: '16px' },
// }));
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
  margin: '10px',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

const Heading = styled('h6')(({ theme }) => ({
  margin: 0,
  marginTop: '4px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.primary.main,
}));

const CenterDashboard = () => {
  const { palette } = useTheme();
  const [managersList, setManagersList] = useState([]);
  const [stocksList, setStocksList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { user } = useAuth();
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchManagerDetails();
    fetchStockDetails();
  }, []);
  const fetchManagerDetails = () => {
    getManagerDetailsByCenterId(user?._id, user?.authToken)
      .then((res) => {
        setManagersList(res.data.data);
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };

  const fetchStockDetails = () => {
    getStocksByRefCenterId(user?._id, user?.authToken)
      .then((res) => {
        setStocksList(res.data.data);
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };
  const stockTypeCounts = stocksList.reduce((acc, stock) => {
    const stockType = stock.stockType?.toUpperCase(); // Convert to uppercase for case-insensitivity

    // Increment the count for the current stockType
    acc[stockType] = (acc[stockType] || 0) + 1;

    return acc;
  }, {});
  console.log(stockTypeCounts.GENERAL || 0);
  // // Dummy data for the number of managers and stocks
  // const numberOfManagers = 5;
  // const numberOfStocks = 100;
  const cardList = [
    {
      name: 'Number of Managers',
      total: managersList.length,
      icon: 'group',
      link: '/center/managerList',
    },
    {
      name: 'Number of Stocks',
      total: stocksList.length,
      icon: 'group',
      link: '/center/stocksList',
    },
  ];
  const chartData = [
    {
      value: stockTypeCounts.GENERAL || 0,
      name: 'General Stock',
    },
    {
      value: stockTypeCounts.ORTHO || 0,
      name: 'Ortho Stock',
    },
  ];

  return (
    <Grid container spacing={3} sx={{ mb: '24px' }}>
      {cardList.map((item, index) => (
        <Grid item xs={12} md={6} key={index}>
          <StyledCard elevation={6} component={Link} to={item.link}>
            <ContentBox>
              <Icon className="icon">{item.icon}</Icon>
              <Box ml="12px">
                <Small>{item.name}</Small>
                <Heading>{item.total}</Heading>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
      ))}
      <Grid item style={{ marginLeft: '10px' }} lg={4} md={4} sm={12} xs={12}>
        <Card sx={{ px: 3, py: 2, mb: 3 }}>
          <Title>Stock Type Breakdown</Title>
          <SubTitle>All stocks in the inventory</SubTitle>

          <DoughnutChart
            chartData={chartData}
            height="300px"
            color={[palette.primary.dark, palette.primary.main, palette.primary.light]}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default CenterDashboard;
